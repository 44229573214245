import { Grid, Typography, List, ListItem } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import GridContainer from '../../GridContainer';

const Vision = () => {
  const { t } = useTranslation('pages');
  return (
    <GridContainer>
      <Typography variant="h3">{t('vision.title')}</Typography>
      <Grid item>
        <Trans key="vision.contents.vision.title">
          <Typography variant="body1">
            {t('vision.contents.vision.title')}
            <strong></strong>
          </Typography>
        </Trans>
      </Grid>
      <Grid item>
        <Trans key="vision.contents.mission.title">
          <Typography variant="body1">
            {t('vision.contents.mission.title')}
            <strong></strong>
          </Typography>
        </Trans>
      </Grid>
      <Grid item>
        <Trans key="vision.contents.areas.title">
          <Typography variant="body1">
            {t('vision.contents.areas.title')}
            <strong></strong>
          </Typography>
        </Trans>
        <List sx={{ listStyle: 'disc' }}>
          {t('vision.contents.areas.contents', { returnObjects: true }).map(
            (content, idx) => (
              <div key={idx}>
                <ListItem sx={{ display: 'list-item' }} key={idx}>
                  {content}
                </ListItem>
              </div>
            ),
          )}
        </List>
      </Grid>
    </GridContainer>
  );
};

export default Vision;
