import React from 'react';
import { Grid, List, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, NavLink } from 'react-router-dom';
import { siteMapData } from './Sitemap/siteMapData';
import { useTranslation } from 'react-i18next';
// import { LogoContainer } from '../Header/HeaderDesktop';
import Logos from '../Logos';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: 10,
  },
  container: {
    margin: '0 20px',
    padding: '20px 0',
    justifyContent: 'space-between',
    '& a': {
      color: '#fff',
      marginRight: '10px',
      textDecoration: 'none',
    },
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  simpleLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    textTransform: 'capitalize',
    fontSize: '0.65rem',
  },
  grid: {
    margin: '0 !important',
  },
  list: {
    '& li': {
      '@media screen and (max-width: 600px)': {
        justifyContent: 'flex-start',
      },
    },
    '& ul,li': {
      color: theme.palette.secondary.main,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  gridItem: {
    padding: '0 !important',
  },
}));

function Footer() {
  const classes = useStyles();
  const { t } = useTranslation('menu');
  const mapSite = siteMapData({ t });

  return (
    <Grid
      alignItems="center"
      justifyContent="center"
      container
      className={classes.footer}
    >
      <Grid
        className={classes.grid}
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 1, sm: 12, md: 12 }}
        maxWidth="lg"
      >
        {mapSite.map((curr, idx) =>
          curr.visible ? (
            <Grid
              className={classes.gridItem}
              item
              xs={1}
              sm={4}
              md={12 / 7}
              lg={1}
              key={idx}
            >
              <LinksContainer
                key={idx}
                classes={classes}
                title={curr.title}
                subMenu={curr.subMenu}
                linkTo={curr.linkTo}
              ></LinksContainer>
            </Grid>
          ) : null,
        )}
      </Grid>
    </Grid>
  );
}

const LinksContainer = ({ title, subMenu, linkTo }) => {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem>
        <Link className={classes.link} to={linkTo}>
          {title}
        </Link>
      </ListItem>
      <List>
        {subMenu.map((curr, idx) => (
          <ListItem key={idx}>
            <Link className={classes.simpleLink} to={curr.linkTo}>
              {curr.title}
            </Link>
          </ListItem>
        ))}
      </List>
    </List>
  );
};

export const FooterTemporary = () => {
  const classes = useStyles();
  const { t } = useTranslation('pages');

  return (
    <>
      <Logos />
      <Grid container className={classes.footer}>
        {/* <LogoContainer color="#fff" /> */}
        <Grid
          className={classes.container}
          container
          justifyContent={'space-between'}
          columns={2}
        >
          <Grid item s={6}>
            <span>
              {t('footer.title')} {t('footer.cf')} {t('footer.sede')}
            </span>
          </Grid>
          <Grid item s={6}>
            <NavLink to="policies/privacy">Privacy Policy</NavLink>
            <NavLink to="policies/cookie">Cookie Policy</NavLink>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
