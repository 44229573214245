import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import BoxDownload from './BoxDownload';
import Pagination from './Pagination';

const useStyles = makeStyles(() => ({
  container: {
    margin: '10px 0px',
  },
  box: {
    width: '100%',
    height: '22rem',
    backgroundColor: 'white',
  },
  boxContainer: {
    padding: 10,
    backgroundColor: 'white',
  },
}));

const ContainerDownload = ({
  rows,
  rowsNumber = 5,
  page,
  handleChangePage,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.boxContainer}>
        {(rowsNumber > 0
          ? rows.slice(page * rowsNumber, page * rowsNumber + rowsNumber)
          : rows
        ).map((row, idx) => (
          <BoxDownload
            key={idx}
            title={row.title}
            url={row.url}
            bgcolor={idx % 2 !== 0}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="flex-end" alignContent="center">
        <Pagination
          count={rows.length}
          rowsPerPage={rowsNumber}
          page={page}
          onChangePage={handleChangePage}
        />
      </Box>
    </>
  );
};

export default ContainerDownload;
