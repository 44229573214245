import React from 'react';
import MenuItem from './MenuItem';
import { useTranslation } from 'react-i18next';

import SubMenuItem from './SubMenuItem';
import { menuData } from './menuData';

import { palette } from '../../../consts/palette';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  header__nav: {
    position: 'sticky',
    width: '100%',
    margin: '0 auto',
    textTransform: 'uppercase',
    fontSize: 15,
    '& ul': {
      listStyleType: 'none',
      display: 'grid',
      gridTemplateColumns: 'repeat(5,1fr)',
      padding: 0,
      margin: 0,
      '& li': {
        textDecoration: 'none',
        width: '100%',
        textAlign: 'center',
        '& a': {
          color: '#fff',
          textDecoration: 'none',
          width: '100%',
          textAlign: 'center',
          padding: '10px 5px',
        },
      },
    },
  },
  submenu: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    position: 'absolute',
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '12px',
    '& a': {
      color: 'white',
      textDecoration: 'none',
      margin: 0,
      padding: 0,
    },
    '&$MuiListItem-root:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  'MuiListItem-root': {},
  main: {
    backgroundColor: theme.palette.primary.main,
    width: '33.3%',
  },
  dark: {
    backgroundColor: theme.palette.primary.dark,
    width: '33.3%',
    opacity: 0.98,
  },
  collapse: {
    height: 250,
  },
  logoSlim: {
    transform: 'scale(0.40)',
    position: 'absolute',
    objectFit: 'contain',
    '@media screen and (max-width: 1280px)': {
      display: 'none',
    },
  },
}));

function Menu() {
  const classes = useStyles();

  const [openHome, setOpenHome] = React.useState(false);
  const [openServices, setOpenServices] = React.useState(false);
  const [openTarget, setOpenTarget] = React.useState(false);
  const [openIter, setOpenIter] = React.useState(false);
  const [openInnovation, setOpenInnovation] = React.useState(false);

  const { t, i18n } = useTranslation('menu');

  const menu = menuData({
    i18n,
    t,
    SubMenuItem,
    openHome,
    setOpenHome,
    openServices,
    setOpenServices,
    openTarget,
    setOpenTarget,
    openIter,
    setOpenIter,
    openInnovation,
    setOpenInnovation,
  });

  return (
    <nav className={classes.header__nav}>
      <ul>
        {menu.map((curr, idx) => (
          <MenuItem
            subMenu={curr.subMenu}
            background={palette[idx]}
            key={idx}
            exact={curr.exact}
            to={curr.to}
            openClose={curr.openCloseCallback}
            labelLink={curr.labelLink}
            target={curr.target}
          ></MenuItem>
        ))}
      </ul>
    </nav>
  );
}

export default Menu;
