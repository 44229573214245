import { Accordion, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import menuHook from '../hook/menu-hook';
import { RESERVED_AREA_LINK } from '../../../../constants';
const useStyles = makeStyles((theme) => ({
  link: {
    color: '#fff',
    textDecoration: 'none',
    '&:hover': {
      color: '#ccc',
    },
  },
}));

const accordionStyles = (deep) => {
  const backgrounds = ['#036ac4', '#035ba8', '#004e91'];
  return {
    accordion: { bgcolor: backgrounds[deep] },
    summary: {
      '&:hover': {
        // backgroundColor: hoverBackgrounds[deep],
        transition: 'all .5s',
      },
    },
  };
};

const MenuItem = ({
  linkTo,
  title,
  prev,
  subMenu,
  deep = 0,
  id,
  openClose,
  sectionOpened,
  openSection,
}) => {
  const classes = useStyles();
  const style = accordionStyles(deep);
  const accId = `panel-${linkTo}`;
  const { accordionClickHandler } = menuHook();

  const onclick = () => {
    accordionClickHandler(
      accId,
      sectionOpened,
      prev,
      openSection,
      openClose,
      subMenu,
      linkTo,
    );
  };

  return (
    <div className={classes.container}>
      <Accordion
        expanded={sectionOpened.includes(accId)}
        sx={style.accordion}
        square
        disableGutters
      >
        <AccordionSummary
          sx={style.summary}
          expandIcon={subMenu && <ExpandMoreIcon style={{ color: '#fff' }} />}
          aria-controls={accId}
          id={accId}
          onClick={onclick}
        >
          {linkTo && linkTo === RESERVED_AREA_LINK ? (
            <a href={linkTo} className={classes.link}>
              {title}
            </a>
          ) : linkTo ? (
            <Link
              className={classes.link}
              onClick={() => openClose(false)}
              state={{ key: accId }}
              to={linkTo}
            >
              {title}
            </Link>
          ) : (
            <Typography className={classes.link}>{title}</Typography>
          )}
        </AccordionSummary>

        {subMenu &&
          subMenu.map((item, idx) => {
            return (
              <MenuItem
                prev={accId}
                openSection={openSection}
                sectionOpened={sectionOpened}
                openClose={openClose}
                key={idx}
                id={idx}
                {...item}
                deep={deep + 1}
              />
            );
          })}
      </Accordion>
    </div>
  );
};

export default MenuItem;

MenuItem.propTypes = {
  openClose: PropTypes.func,
  state: PropTypes.bool,
};
