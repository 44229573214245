import { Grid, List, ListItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { boxesPalette } from '../data/palette';
const useStyles = makeStyles((theme) => ({
  box: {
    color: '#fff',
    boxSizing: 'border-box',
    padding: 25,
    border: '1px solid #ccc',
    borderRadius: 5,
    textAlign: 'center',
    '& a': {
      color: '#fff',
    },
  },
}));
const BoxContainer = ({ triggerOpen, title, contents, idx }) => {
  const classes = useStyles();
  const clickHandler = () => triggerOpen(true);
  return (
    <Grid
      item
      sx={{ backgroundColor: boxesPalette[idx] }}
      className={classes.box}
      xs={12}
      md={3.8}
    >
      <Typography variant="h5">{title}</Typography>
      <List sx={{ listStyle: 'circle', marginTop: 1 }}>
        {contents?.map((content, idx) => (
          <Trans key={idx}>
            <ListItem sx={{ display: 'list-item' }} key={idx}>
              {content}
              <Link to="" onClick={clickHandler}></Link>
            </ListItem>
          </Trans>
        ))}
      </List>
    </Grid>
  );
};
export default BoxContainer;
