import { RESERVED_AREA_LINK } from '../../../../constants';
export const menuMobileData = ({ t, i18n }) => {
  return [
    {
      exact: false,
      linkTo: '/home',
      title: t('menu.home'),
    },
    {
      exact: false,
      linkTo: '/about',
      title: t('menu.whoWeAre'),
      subMenu: [
        {
          keyName: 'mission',
          title: t('whoWeAre.mission'),
          linkTo: '/about/vision-and-mission',
        },
        {
          keyName: 'organs',
          title: t('whoWeAre.organs'),
          linkTo: '/about/organisational-structure',
        },
      ],
    },
    {
      exact: false,
      to: '/join-us',
      title: t('menu.subscription'),
      subMenu: [
        {
          keyName: 'whySubscribe',
          title: t('subscription.whySubscribe'),
          linkTo: '/join-us/why-assonave',
        },
        {
          keyName: 'modules',
          title: t('subscription.modules'),
          linkTo: '/join-us/instructions-and-applications-forms',
        },
        {
          keyName: 'contributes',
          title: t('subscription.contributes'),
          linkTo: '/join-us/subscription-fees',
        },
      ],
    },
    {
      exact: false,
      linkTo: '/shareholders',
      title: t('menu.shareholders'),
      subMenu: [
        {
          keyName: 'ordinaryList',
          title: t('shareholders.ordinaryList'),
          linkTo: '/shareholders/ordinary',
        },
        {
          keyName: 'ordinaryRequirements',
          title: t('shareholders.ordinaryRequirements'),
          linkTo: '/shareholders/ordinary/requirements',
        },
        {
          keyName: 'aggregateList',
          title: t('shareholders.aggregateList'),
          linkTo: '/shareholders/aggregate',
        },
        {
          keyName: 'aggregateRequirements',
          title: t('shareholders.aggregateRequirements'),
          linkTo: '/shareholders/aggregate/requirements',
        },
        {
          keyName: 'grinavRequirements',
          title: t('shareholders.grinavRequirements'),
          linkTo: '/shareholders/grinav',
        },
        {
          keyName: 'grinavRequirements',
          title: t('shareholders.grinavRequirements'),
          linkTo: '/shareholders/grinav/requirements',
        },
      ],
    },
    // {
    //   exact: false,
    //   linkTo: '/documents',
    //   title: t('menu.documents'),
    //   subMenu: [
    //     {
    //       keyName: 'documents',
    //       title: t('documents.documents'),
    //       linkTo: '/documents/documents',
    //     },
    //     {
    //       keyName: 'news',
    //       title: t('documents.news'),
    //       linkTo: '/documents/news',
    //     },
    //     {
    //       keyName: 'membership',
    //       title: t('documents.membership'),
    //       linkTo: '/documents/membership',
    //     },
    //   ],
    // },
    // {
    //   exact: false,
    //   linkTo: '/login',
    //   title: t('menu.login'),
    // },
    {
      exact: false,
      linkTo: '/contacts',
      title: t('menu.contacts'),
    },
    {
      exact: false,
      linkTo: RESERVED_AREA_LINK,
      title: t('menu.reservedArea'),
    },
  ];
};
