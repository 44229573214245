import React from 'react';

const A = ({ href, children, show }) => {
  if (!show) return null;
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default A;
