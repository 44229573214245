import { List, ListItem, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getShareholderList } from '../../../data/Shareholders/shareholdersData.ts';
import { BlockContainer } from '../../GridContainer';
import LanguageIcon from '@mui/icons-material/Language';
import ContactPageIcon from '@mui/icons-material/ContactPage';
const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 5,
  },
}));
const ShareholderList = ({ type }) => {
  const classes = useStyles();
  const _shareholder_url = `/shareholders/${type}/list`;
  const { t } = useTranslation();
  const [shareholders, setShareholders] = useState([]);
  useEffect(() => setShareholders(getShareholderList(t, type)), [t, type]);
  return (
    <BlockContainer>
      <List>
        <Typography variant="h3">{t(`common:shareholders.${type}`)}</Typography>
        {shareholders?.map((shareholder) => (
          <ListItem key={shareholder.id}>
            {shareholder.page ? (
              <Tooltip
                title={t('common:actions.openCard')}
                placement="left-start"
              >
                <NavLink
                  className={classes.link}
                  to={`${_shareholder_url}/${shareholder.id}`}
                >
                  <ContactPageIcon />
                  {shareholder.name}
                </NavLink>
              </Tooltip>
            ) : shareholder.link ? (
              <Tooltip
                title={t('common:actions.openWebsite')}
                placement="left-start"
              >
                <a
                  className={classes.link}
                  href={shareholder.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LanguageIcon /> {shareholder.name}
                </a>
              </Tooltip>
            ) : (
              <label className={classes.link}>{shareholder.name}</label>
            )}
          </ListItem>
        ))}
      </List>
    </BlockContainer>
  );
};

export default ShareholderList;
