import React, { useEffect, useState } from 'react';
import {
  Box,
  useTheme,
  Typography,
  Hidden,
  Fade,
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ToggleButton from '../../theme/ToggleButton';

const useStyles = makeStyles((theme) => ({
  slider: {
    display: 'flex',
    padding: '0px 0px',
    flexFlow: 'column wrap',
    margin: '0 0 20px 0px',
    maxWidth: '100%',
    '@media screen and (max-width: 400px)': {
      minHeight: '20%',
      maxHeight: '20%',
    },
    '@media screen and (max-width: 400px) and (orientation: portrait)': {
      height: '50vh',
      minHeight: '250px',
    },
  },
  activeImage: {},
  imgslide: {},
  slider__image: {
    minHeight: '500px',
    height: '100%',
    position: 'relative',
    margin: '0px 0px',
    padding: '0px 0px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    boxPack: 'center',
    justifyContent: 'center',
    opacity: 0,
    transitionDuration: '1.5s ease',
    overflow: 'hidden',
    '& > img': {
      objectFit: (props) => props.objectFit || 'cover',
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'block',
      margin: 'auto auto',
      transition: 'left 1s',
      opacity: (props) => props.opacity,
      '@media screen and (max-width: 900px)': {
        // objectFit: 'contain !important',
      },
    },
    '&$activeImage': {
      opacity: '1',
      transitionDuration: '1s',
      transform: 'scale(1)',
    },
    '@media screen and (max-width: 400px)': {
      minHeight: '150px',
    },
  },
  imgBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    background:
      'linear-gradient(180deg, rgba(255,255,255,0) 40%, rgba(0,78,145,.5) 100%)',
  },
  active: {
    opacity: '1',
    transitionDuration: '1s',
    transform: 'scale(1)',
  },
  slider__text: {
    color: 'white',
    position: 'absolute',
    fontWeight: 'bold',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto auto',
    textTransform: 'uppercase',
  },
  slider__bottom: {
    margin: '0px 0px',
    minHeight: '50px',
    maxHeight: '50px',
    display: 'flex',
    justifyContent: 'left',
    padding: '0px 15px',
    alignItems: 'center',
    color: 'white',
    textTransform: 'uppercase',
  },
  slider__nav: {
    display: 'flex',
    justifyContent: 'center',
  },
  squarebracketsDiv: {
    '& img': {
      top: '25%',
      '-moz-transform': 'scale(0.8)',
      '-webkit-transform': 'scale(0.8)',
      transform: 'scale(0.8)',
    },
    '@media screen and (max-width: 600px)': {
      width: '80%',
      height: '20%',
      '& img': {
        top: '3px',
        '-moz-transform': 'scale(0.6)',
        '-webkit-transform': 'scale(0.6)',
        transform: 'scale(0.6)',
      },
    },
  },
}));
function Slider({
  imageData,
  textBottom = null,
  textSlider = null,
  timer = 5,
  square = false,
  opacity = 1,
  noshadow,
  objectFit,
}) {
  const [currentImage, setCurrentImage] = useState(0);
  const classes = useStyles({ opacity, objectFit });
  const theme = useTheme();

  useEffect(() => {
    const interval = setInterval(() => {
      timer !== 0 &&
        setCurrentImage(
          (currentImage) => (currentImage + 1) % imageData.length,
        );
    }, timer * 1000);
    return () => clearInterval(interval);
  });

  if (!Array.isArray(imageData) || imageData.length <= 0) {
    return null;
  }

  return (
    <Container maxWidth="lg">
      <Box className={classes.slider}>
        {imageData.map((image, index) => {
          return (
            index === currentImage && (
              <Box
                key={index}
                className={
                  index === currentImage
                    ? `${classes.slider__image} ${classes.active}`
                    : `${classes.slider__image}`
                }
              >
                {!noshadow && <div className={classes.imgBackground}></div>}
                <Fade timeout={1000} in={index === currentImage}>
                  <img
                    className={`${classes.slider__image}  ${classes.imgslide}`}
                    key={index}
                    src={image.image}
                    alt=""
                  />
                </Fade>
              </Box>
            )
          );
        })}
        <Hidden xsDown>
          {textBottom && false && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              bgcolor="primary.main"
              className={classes.slider__bottom}
            >
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: textBottom }}
              ></Typography>
            </Box>
          )}
        </Hidden>

        <Box className={classes.slider__nav}>
          {[].map((image, index) => (
            <ToggleButton
              marginbutton={5}
              widthbutton={0}
              heightbutton={8}
              backgroundcolor={
                index === currentImage
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main
              }
              key={index}
              onClick={() => {
                setCurrentImage(index);
              }}
            />
          ))}
        </Box>
      </Box>
    </Container>
  );
}

Slider.propTypes = {
  imageData: PropTypes.arrayOf(PropTypes.object),
  textBottom: PropTypes.string,
  textSlider: PropTypes.string,
  timer: PropTypes.number,
};

export default Slider;
