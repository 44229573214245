export const shareholdersData = (t) => [
  {
    title: t('menu:shareholders.ordinary'),
    requirementsUrl: {
      to: '/shareholders/ordinary/requirements',
      title: t('menu:shareholders.requirement'),
    },
    listUrl: {
      to: '/shareholders/ordinary',
      title: t('menu:shareholders.list'),
    },
  },
  {
    title: t('menu:shareholders.aggregate'),
    requirementsUrl: {
      to: '/shareholders/aggregate/requirements',
      title: t('menu:shareholders.requirement'),
    },
    listUrl: {
      to: '/shareholders/aggregate',
      title: t('menu:shareholders.list'),
    },
  },
  {
    title: t('menu:shareholders.grinav'),
    requirementsUrl: {
      to: '/shareholders/grinav/requirements',
      title: t('menu:shareholders.requirement'),
    },
    listUrl: {
      to: '/shareholders/grinav',
      title: t('menu:shareholders.list'),
    },
  },
];
