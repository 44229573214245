import React from 'react';
import { Grid, Button, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation('common');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lang, setLang] = React.useState(i18n.language);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [mouse, setHover] = React.useState('');
  const styles = {
    marginRight: '5px',
    cursor: mouse,
  };
  const chooseLang = (langChoosen) => {
    setLang(langChoosen);
    localStorage.setItem('language', langChoosen);
    i18n.changeLanguage(langChoosen);
    handleClose();
  };

  return (
    <Grid container wrap="nowrap" justify="space-between" alignItems="center">
      <LinkedinIcon
        style={styles}
        onMouseEnter={() => setHover('pointer')}
        onClick={() =>
          window.open('https://www.linkedin.com/company/assonave/')
        }
      ></LinkedinIcon>
      <LanguageIcon></LanguageIcon>
      <Grid item xs={6}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <span style={{ color: '#fff' }}>{lang}</span>
          <ArrowDropDownIcon style={{ color: '#fff' }}></ArrowDropDownIcon>
        </Button>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            chooseLang('en');
          }}
        >
          EN
        </MenuItem>
        <MenuItem
          onClick={() => {
            chooseLang('it');
          }}
        >
          IT
        </MenuItem>
      </Menu>
    </Grid>
  );
}

export default LanguageSelector;
