import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  container: {
    margin: '50px 0',
    '@media screen and (max-width: 600px)': {
      margin: '25px 0',
    },
  },
  grid: {
    rowGap: 10,
    margin: 20,
    width: 'unset !important',
  },
  childrenContainer: {},
}));
export const GridContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Grid container flexDirection="column" className={classes.grid}>
        {children}
      </Grid>
    </Container>
  );
};

export const BlockContainer = ({ children, sx }) => {
  const classes = useStyles();
  return (
    <Container sx={sx} className={classes.container}>
      {children}
    </Container>
  );
};

export default GridContainer;
