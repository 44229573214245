import { Backdrop, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '80%',
    maxHeight: '95vh',
    overflow: 'hidden',
    background: theme.palette.primary.main,
    borderRadius: 5,
    color: '#fff',
    padding: 20,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.mainOpaque,
    },
  },
}));
const Benchmarks = ({ opened, triggerOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation('pages');
  const contents = t('subscriptionFeesInfo.contents', { returnObjects: true });
  const clickHandler = () => triggerOpen(!opened);
  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.backdropIndex }}
      open={opened}
      onClick={() => triggerOpen(!opened)}
    >
      <Stack className={classes.container}>
        <CloseRoundedIcon
          onClick={clickHandler}
          className={classes.closeButton}
        />
        <Box>
          <Typography sx={{ textAlign: 'center', color: '#fff' }} variant="h6">
            {t('subscriptionFeesInfo.title')}
          </Typography>
          <Typography sx={{ textAlign: 'center', color: '#fff' }} variant="h6">
            {t('subscriptionFeesInfo.subtitle')}
          </Typography>
        </Box>
        {contents.map((content, idx) => (
          <Typography key={idx} variant="body1">
            {content}
          </Typography>
        ))}
      </Stack>
    </Backdrop>
  );
};

export default Benchmarks;
