import * as React from 'react';
import { menuMobileData } from './data/menuData';
import { useTranslation } from 'react-i18next';
import MenuItem from './components/MenuItem';
import { Stack } from '@mui/material';

export default function MenuContainer(props) {
  const { t, i18n } = useTranslation('menu');
  const menu = menuMobileData({ t, i18n });
  const [sectionOpened, openSection] = React.useState('');
  return (
    <Stack>
      {menu.map((item, idx) => (
        <MenuItem
          sectionOpened={sectionOpened}
          openSection={openSection}
          {...props}
          {...item}
          key={idx}
          id={idx}
        />
      ))}
    </Stack>
  );
}
