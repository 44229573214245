import BoxSquared from '../../../../components/BoxSquared';
import { useTranslation } from 'react-i18next';
import { Container, Grid, SvgIcon } from '@mui/material';
import { dataBox } from '../data/dataBox';

function HomeBox() {
  const { t } = useTranslation();
  const box = dataBox(t);
  return (
    <Container>
      <Grid
        style={{ width: '100%', margin: '0 auto', rowGap: '10px' }}
        container
        justify="center"
        direction="row"
        wrap="wrap"
      >
        <Grid
          container
          style={{ width: '100%', margin: '0 auto', rowGap: '25px' }}
        >
          {box &&
            box.map((el, idx) => (
              <Grid item xs={12} sm={6} md={4} key={idx}>
                <BoxSquared
                  title={el.title}
                  url={el.url}
                  icon={
                    <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                      {el.icon}
                    </SvgIcon>
                  }
                  transition={0}
                  transform={1}
                  imageBox={el.imageBox}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomeBox;
