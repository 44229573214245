import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import ContentContainer from './ContentContainer';
// import LoginBox from './LoginBox';
import homeImg from '../../../../assets/img/home_img.png';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    margin: '50px auto',
    '@media screen and (max-width: 600px)': {
      marginTop: '50px !important',
    },
  },
  box: {
    flex: '1 1',
    width: '100%',
  },
  image: {
    width: '100%',
  },
}));

const MainContent = () => {
  const classes = useStyles();
  return (
    <Container>
      <Grid className={classes.container} container direction="row" spacing={2}>
        <Grid md={8} xs={12} item className={classes.box}>
          <ContentContainer />
        </Grid>
        <Grid md={4} xs={12} item className={classes.boxw}>
          {/* <LoginBox /> */}
          <img
            className={classes.image}
            src={homeImg}
            alt="assonave home"
          ></img>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainContent;
