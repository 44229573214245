// eslint-disable-next-line no-unused-vars
const hiddenRoutes = [
  '/policies',
  '/contacts',
  'not-found',
  'shareholders/aggregate/list',
  'shareholders/ordinary/list',
  'shareholders/grinav/list',
];

// const showRoutes = ['/', '/home', '/index.html'];

export const HiddenRoutes = (location) => {
  const found =
    hiddenRoutes.find((r) => location.pathname.includes(r)) === undefined;
  return found;
};
