import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import logo from '../../../../assets/img/circle-logo.png';
import React from 'react';
import PaletteContainer from '../../../../components/Palette';
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  title: {
    color: theme.palette.primary.main,
  },
  logo: {
    width: '75px',
    position: 'absolute',
    top: '-30px',
    right: '50%',
    transform: 'translateX(50%)',
  },
}));
const Name = ({ name = '' }) => {
  const classes = useStyles();
  const title = name.split('–');
  return (
    <Container>
      <Grid container direction="row" spacing={2}>
        <Grid md={4} xs={12} item className={classes.box}></Grid>
        <Grid md={8} xs={12} item className={classes.container}>
          <img className={classes.logo} src={logo} alt=""></img>
          <PaletteContainer size={10} />
          <Grid style={{ margin: '20px 0' }}>
            {title?.map((text, idx) => (
              <Typography
                className={classes.title}
                key={idx}
                align="center"
                variant="h3"
                component="h3"
              >
                {text}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Name;
