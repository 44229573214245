import { imageDataBox } from './imageDataBox';
import { ReactComponent as Icon1 } from '../../../../assets/icon/HomeBox1.svg';
/* import { ReactComponent as Icon2 } from '../../../../../assets/icon/HomeBox2.svg';
 */
import { ReactComponent as Icon4 } from '../../../../assets/icon/HomeBox4.svg';
import { ReactComponent as Icon5 } from '../../../../assets/icon/foglia.svg';

export const dataBox = (t) => [
  {
    title: t('menu:menu.subscription'),
    imageBox: imageDataBox[0].image,
    url: '/join-us',
    icon: <Icon1 />,
  },
  {
    title: t('menu:menu.shareholders'),
    imageBox: imageDataBox[1].image,
    url: '/shareholders',
    icon: <Icon4 />,
  },
  {
    title: t('menu:menu.documents'),
    imageBox: imageDataBox[2].image,
    url: '/documents',
    icon: <Icon5 />,
  },
];
