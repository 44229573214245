import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PaletteContainer, { PaletteWithLogo } from '../../../components/Palette';
import { BlockContainer } from '../../GridContainer';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: '100%',
    minHeight: '600px',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 5,
  },
  container: {
    marginTop: '3rem',
    color: theme.palette.primary.main,
  },
  mapItem: {
    marginBottom: '4rem',
  },
}));

const SimpleMap = () => {
  const { t } = useTranslation('pages');
  const sites = t('contacts.whereAreWe.sites', {
    returnObjects: true,
  });
  const classes = useStyles();
  return (
    <BlockContainer>
      <iframe
        className={classes.mapContainer}
        title="Google map"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=Via%20Tevere,%201a,%2000198%20Roma%20RM&t=&z=13&ie=UTF8&iwloc=&output=embed&key=AIzaSyD2OnpnqQYxiwDl6PWHLxDqc-9OtvsK8co"
      />
      <Grid className={classes.container} container>
        <Grid item xs={12} sm={12} md={8}>
          <Box sx={{ width: '95%' }}>
            <PaletteWithLogo />
            <Typography
              sx={{ margin: '25px 0 10px 0' }}
              variant="h3"
              component="h3"
            >
              Assonave
            </Typography>
            <Typography variant="h4" component="h4">
              {t('home.title')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {sites.map((site, idx) => (
            <MapItem key={idx} {...site} margin={'.5rem'} />
          ))}
        </Grid>
      </Grid>
    </BlockContainer>
  );
};

const MapItem = ({ title, contents = [], logo, margin = 5 }) => {
  const classes = useStyles();
  return (
    <Grid flexDirection="column" container className={classes.mapItem}>
      {logo ? <PaletteWithLogo size={10} /> : <PaletteContainer size={40} />}
      <Typography variant="h6">{title}</Typography>
      {contents.map((direction, idx) => (
        <Typography key={idx} sx={{ margin: `${margin}px 0` }} variant="body1">
          {direction}
        </Typography>
      ))}
    </Grid>
  );
};

export default SimpleMap;
