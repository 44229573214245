import box1 from '../../../../assets/img/box/1.jpeg';
import box2 from '../../../../assets/img/box/2.jpeg';
import box3 from '../../../../assets/img/box/3.jpeg';

export const imageDataBox = [
  {
    key: 'box1',
    image: box1,
  },
  {
    key: 'box2',
    image: box2,
  },
  {
    key: 'box3',
    image: box3,
  },
];
