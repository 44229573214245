import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GridContainer from '../../GridContainer';
import ContainerDownload from './BoxDownload/ContainerDownload';
import getDocumentsData from './data/documentsLinks';

const Documents = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [page, setPage] = useState(0);
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const containerDownloadRows = getDocumentsData(language);

  return (
    <GridContainer>
      <Grid item xs={12}>
        <Typography variant="h3">{t('pages:documents.title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ContainerDownload
          rows={containerDownloadRows}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Grid>
    </GridContainer>
  );
};

export default Documents;
