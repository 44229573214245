const palette = [
  '#2069ad',
  '#407aac',
  '#598bb7',
  '#739dc2',
  '#8cafcd',
  '#a6c1d8',
  '#b3d0e9',
];
export { palette };
