import React from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Palette from '../Palette';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Box, Typography } from '@mui/material';
const useStyles = makeStyles((theme) => {
  return {
    parent: {
      backgroundColor: 'white',
      height: (props) => (props.heightFixed ? '18rem' : '100%'),
      width: '95%',
      '@media screen and (max-width: 500px)': {
        height: (props) => props.heightFixed && '25rem',
      },
      overflow: 'hidden',
      flexDirection: 'column',
      color: theme.palette.primary.main,
      position: 'relative',
    },
    boxContainer: {
      marginTop: 1,
      height: '100%',
      overflow: 'hidden',
    },
    box: {
      backgroundImage: (props) => props.imageBox && `url("${props.imageBox}")`,
      backgroundSize: (props) => props.imageBox && 'cover',
      backgroundRepeat: (props) => props.imageBox && 'no-repeat',
      backgroundPosition: (props) => props.imageBox && 'center center',
      padding: (props) =>
        !props.operatingProceduress ? theme.spacing(3) : theme.spacing(0),
      textAlign: 'center',
      color: theme.palette.secondary.main,
      marginBottom: theme.spacing(0),
      borderRadius: 0,
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      justifyContent: 'flex-end',
      textTransform: 'capitalize',
      transition: (props) => `transform ${props.transition}s`,
      filter: 'brightness(70%)',
      '&:hover': {
        transform: `scale(1.1)`,
        transition: 'transform 0.5s',
      },
      '&:focus': {
        transform: (props) => `scale(${props.transform})`,
      },
    },
    containerBox: {
      height: (props) => (props.heightFixed ? '100%' : ''),
    },
    lineHeigth: {
      lineHeight: (props) => (props.classFirstBox ? '' : '1.05'),
      margin: '0px 0px',
    },
    image: {
      backgroundColor: 'white',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      '& img': {
        objectFit: 'contain',
        height: '100%',
      },
    },
    titleContainer: {
      position: 'absolute',
      bottom: 20,
      left: '50%',
      transform: 'translateX(-50%)',
      color: '#fff',
      zIndex: 1,
      whiteSpace: 'nowrap',
    },
    buttonIcon: {
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.1)',
        color: theme.palette.primary.light,
        transition: 'all .5s',
      },
    },
  };
});

const BoxSquared = (props) => {
  const {
    title = 'title',
    classFirstBox = false,
    heightFixed = true,
    operatingProceduress = false,
    imageBox = null,
    url = 'null',
    transition = 0,
    transform = 1,
    fontweight = true,
  } = props;

  const classes = useStyles({
    operatingProceduress,
    imageBox,
    heightFixed,
    classFirstBox,
    transition,
    transform,
    fontweight,
  });

  const navigate = useNavigate();
  const handleClick = () => {
    if (url !== 'null') navigate(url);
  };

  return (
    <Box className={classes.parent} display="flex">
      <Palette size={25}>
        <ArrowCircleRightOutlinedIcon
          className={classes.buttonIcon}
          onClick={handleClick}
        />
      </Palette>
      <div className={classes.boxContainer}>
        <Box
          className={classes.box}
          display="flex"
          flexDirection="column"
          onClick={handleClick}
        ></Box>
      </div>
      <Typography
        className={classes.titleContainer}
        variant="span"
        gutterBottom
      >
        {title}
      </Typography>
    </Box>
  );
};

BoxSquared.propTypes = {
  title: PropTypes.string,
  titleCenter: PropTypes.bool,
  variantText: PropTypes.string,
  subText: PropTypes.string,
  subContent: PropTypes.any,
  variantSubText: PropTypes.string,
  colorTitle: PropTypes.string,
  colorText: PropTypes.string,
  backgroundColor: PropTypes.string,
  classFirstBox: PropTypes.bool,
  heightFixed: PropTypes.bool,
  widthFixed: PropTypes.bool,
  textAlign: PropTypes.string,
  padding: PropTypes.string,
  operatingProceduress: PropTypes.bool,
  image: PropTypes.string,
  imageBox: PropTypes.string,
  url: PropTypes.string,
  transition: PropTypes.number,
  transform: PropTypes.number,
};

export default BoxSquared;
