import { Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import GridContainer from '../../GridContainer';
import { links } from './data/links';

const AboutUs = () => {
  const { t } = useTranslation('pages');
  return (
    <GridContainer>
      <Typography variant="h3">{t('about.title')}</Typography>
      {t('about.contents', { returnObjects: true }).map((content, idx) => (
        <Typography key={idx} variant="body1">
          <Trans key={idx} i18nKey="about.contents">
            {content}
            <a href={links[idx][0]} target="_blank" rel="noreferrer">
              {}
            </a>
            <a href={links[idx][1]} target="_blank" rel="noreferrer">
              {}
            </a>
            <a href={links[idx][2]} target="_blank" rel="noreferrer">
              {}
            </a>
            <a href={links[idx][3]} target="_blank" rel="noreferrer">
              {}
            </a>
          </Trans>
        </Typography>
      ))}
      <Typography></Typography>
    </GridContainer>
  );
};

export default AboutUs;
