import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { FooterTemporary } from './Footer/Footer';
import Header from './Header/Header';
import Home from './pages/Home';
import React, { Suspense, useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import Shareholders from './pages/Shareholders';
import ShareHolderData from './pages/ShareholderData';
import ShareholderList from './pages/ShareholderList';
import AboutUs from './pages/AboutUs';
import Vision from './pages/Vision&Mission';
import Subscribe from './pages/Subscribe';
import HowToSubscribe from './pages/HowTosubscribe';
import SubscriptionFees from './pages/SubscriptionFees';
import ShareholdersRequirements from './pages/ShareholdersRequirements';
import Site from './pages/Site';
import CookiePolicy from './pages/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Documents from './pages/Documents';
import NotFound from './pages/ShareholderData/NotFound';
import OrganizationalStructure from './OrganisationalStructure';
import OrganizationalStructureMember from './OrganisationalStructure/OrganizationalStructureMember';
const routes = [
  { exact: true, path: '/', component: <Home /> },
  { exact: true, path: '/home', component: <Home /> },
  { exact: true, path: '/index.html', component: <Home /> },
  { exact: true, path: '/about', component: <AboutUs /> },
  { exact: true, path: '/shareholders', component: <Shareholders /> },
  {
    exact: true,
    path: '/shareholders/ordinary',
    component: <ShareholderList type="ordinary" />,
  },
  {
    exact: true,
    path: '/shareholders/aggregate',
    component: <ShareholderList type="aggregate" />,
  },
  {
    exact: true,
    path: '/shareholders/grinav',
    component: <ShareholderList type="grinav" />,
  },
  {
    exact: true,
    path: '/shareholders/ordinary/list/:id',
    component: <ShareHolderData type="ordinary" />,
  },
  {
    exact: true,
    path: '/shareholders/aggregate/list/:id',
    component: <ShareHolderData type="aggregate" />,
  },
  {
    exact: true,
    path: '/shareholders/grinav/list/:id',
    component: <ShareHolderData type="grinav" />,
  },
  {
    exact: true,
    path: '/shareholders/ordinary/requirements',
    component: <ShareholdersRequirements shareholderType="ordinary" />,
  },
  {
    exact: true,
    path: '/shareholders/aggregate/requirements',
    component: <ShareholdersRequirements shareholderType="aggregate" />,
  },
  {
    exact: true,
    path: '/shareholders/grinav/requirements',
    component: <ShareholdersRequirements shareholderType="grinav" />,
  },
  {
    exact: true,
    path: '/about/vision-and-mission',
    component: <Vision />,
  },
  {
    exact: true,
    path: '/about/organisational-structure',
    component: <OrganizationalStructure />,
  },
  {
    exact: true,
    path: '/about/organisational-structure/:id',
    component: <OrganizationalStructureMember />,
  },
  {
    exact: true,
    path: '/join-us',
    component: <Subscribe />,
  },
  {
    exact: true,
    path: 'join-us/why-assonave',
    component: <Subscribe />,
  },
  {
    exact: true,
    path: 'join-us/instructions-and-applications-forms',
    component: <HowToSubscribe />,
  },
  {
    exact: true,
    path: 'join-us/subscription-fees',
    component: <SubscriptionFees />,
  },
  {
    exact: true,
    path: 'contacts',
    component: <Site />,
  },
  {
    exact: true,
    path: 'policies/cookie',
    component: <CookiePolicy />,
  },
  {
    exact: true,
    path: 'policies/privacy',
    component: <PrivacyPolicy />,
  },
  {
    exact: true,
    path: 'documents',
    component: <Documents />,
  },
  { exact: false, path: '/not-found', component: <NotFound /> },
  { exact: false, path: '*', component: <Navigate to="/not-found" replace /> },
];

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: 'smooth',
      });
    }, [pathname]);

    return null;
  };

  return (
    <Suspense fallback="loading">
      <CssBaseline></CssBaseline>
      <Router>
        <ScrollToTop></ScrollToTop>
        <Header />
        <Routes>
          {routes.map((curr, idx) => (
            <Route
              key={idx}
              exact={curr.exact}
              path={curr.path}
              element={curr.component}
            ></Route>
          ))}
        </Routes>
        <FooterTemporary />
      </Router>
    </Suspense>
  );
}

export default App;
