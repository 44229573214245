import React from 'react';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import { Box } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import MuiListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';

const ListItem = withStyles((theme) => ({
  root: {
    '&:hover': {},
  },
  selected: {},
}))(MuiListItem);

const useStyles = makeStyles((theme) => ({
  subMenuContainer: {
    background: theme.palette.primary.mainOpaque,
    '& li': {
      borderBottom: '1px solid #fff',
      '&:last-child': {
        border: 'none',
      },
    },
  },
  subMenuListItems: {
    '&:before': {
      border: 'none',
      content: '" " !important',
      padding: '20px',
    },
  },
}));

function SubMenuItem({ title, closeCallback, subMenuList }) {
  const ListItemCustom = ({ children, onMouseLeave, onMouseEnter }) => {
    return (
      <ListItem onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
        {children}
      </ListItem>
    );
  };
  const classes = useStyles();
  return (
    <Box padding={0} className={classes.subMenuContainer}>
      <List component="nav">
        {subMenuList.map((curr, idx) => (
          <ListItemCustom key={idx}>
            <NavLink to={curr.linkTo}>{curr.title}</NavLink>
          </ListItemCustom>
        ))}
      </List>
    </Box>
  );
}

SubMenuItem.propTypes = {
  title: PropTypes.string,
  closeCallback: PropTypes.func,
  subMenuList: PropTypes.arrayOf(PropTypes.object),
};

export default SubMenuItem;
