import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { shareholdersData } from './data/shareholders';
import { useTranslation } from 'react-i18next';
import ShareholderBox from './components/ShareholderBox';
const useStyles = makeStyles((theme) => ({
  grid: {
    margin: '50px 0',
  },
}));
const Shareholders = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const shareHolders = shareholdersData(t);
  return (
    <Container className={classes.container}>
      <Typography variant="h3">{t('pages:shareholders.title')}</Typography>
      <Grid className={classes.grid} container>
        {shareHolders &&
          shareHolders.map((shareholder, idx) => (
            <ShareholderBox {...shareholder} key={idx} />
          ))}
      </Grid>
    </Container>
  );
};

export default Shareholders;
