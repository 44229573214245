import MuiTypography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BlockContainer } from '../../GridContainer';

const Typography = withStyles((theme) => ({
  root: {
    marginBottom: 15,
  },
  subtitle1: {
    fontWeight: 'bold',
    paddingTop: '15px',
  },
  subtitle2: {
    fontWeight: 'bold',
    size: '1rem',
    lineHeight: 1.5,
  },
  subtitle3: {
    fontWeight: 'bold',
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: 'white',
    padding: 15,
    '& > ul li': {
      fontSize: '1rem',
    },
  },
  table: {
    backgroundColor: theme.palette.secondary.morelight,
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    margin: 20,
  },
  boxWhite: {
    padding: '20px 20px',
    minHeight: '120px',
    backgroundColor: theme.palette.primary.plusLight,
  },
  boxGrey: {
    padding: '20px 20px',
    minHeight: '120px',
    backgroundColor: theme.palette.secondary.light,
  },
  text: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
  boxContainer: {
    width: '100%',
    padding: 10,
    backgroundColor: 'white',
  },
  orange: {
    padding: 3,
    height: '100%',
    backgroundColor: theme.palette.primary.light,
  },
  grey: {
    padding: 3,
    height: '100%',
    backgroundColor: theme.palette.secondary.light,
  },
  cont: {
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& ul': {
      listStyle: 'none',
      fontSize: '1rem',
      marginLeft: -20,
      '&  li': {
        fontSize: '0.93rem',
        '&:before': {
          color: theme.palette.primary.main,
          content: '"\\2022"',
          fontWeight: 'bold',
          display: 'inline-block',
          width: '1em',
        },
      },
    },
  },
  assonave: {
    color: theme.palette.primary.main,
    fontSize: 'bold',
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const LinkAssonave = ({ translation }) => {
    return (
      <a target="_blank" rel="noreferrer" href="https://www.assonave.it">
        {translation}
      </a>
    );
  };

  const rightsOfDataSubjects = [
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_one.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:privacy.paragraph_eight.table.row_one.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_one.three')}
          </Typography>
        </>
      ),
    },
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_two.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:privacy.paragraph_eight.table.row_two.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_two.three')}
          </Typography>
        </>
      ),
    },
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_three.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:privacy.paragraph_eight.table.row_three.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_three.three')}
          </Typography>
        </>
      ),
    },
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_four.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:privacy.paragraph_eight.table.row_four.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_four.three')}
          </Typography>
        </>
      ),
    },
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_five.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:privacy.paragraph_eight.table.row_five.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_five.three')}
          </Typography>
        </>
      ),
    },
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_six.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:privacy.paragraph_eight.table.row_six.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:privacy.paragraph_eight.table.row_six.three')}
          </Typography>
        </>
      ),
    },
  ];

  return (
    <BlockContainer>
      <Grid
        container
        alignItems="center"
        direction="column"
        wrap="wrap"
        spacing={1}
        className={classes.cont}
      >
        <Typography className={classes.title} variant="h4">
          {t('pages:privacy.title')}
        </Typography>
        <Box className={classes.box}>
          <Typography>
            {t('pages:privacy.intro_one.0')}{' '}
            <LinkAssonave translation={t('pages:privacy.intro_one.1')} />
            {t('pages:privacy.intro_one.2')}
          </Typography>
          <Typography>{t('pages:privacy.intro_two')}</Typography>
          <Typography variant="subtitle1">
            {t('pages:privacy.paragraph_one.title')}
          </Typography>
          <Typography>
            {t('pages:privacy.paragraph_one.one.0')}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:privacy@assonave.it"
            >
              {t('pages:privacy.paragraph_one.one.1')}
            </a>
          </Typography>
          <Typography variant="subtitle1">
            {t('pages:privacy.paragraph_two.title')}
          </Typography>
          <Typography> {t('pages:privacy.paragraph_two.one')}</Typography>
          <Typography variant="subtitle1">
            <strong>{t('pages:privacy.paragraph_two.twoB')} </strong>
            {t('pages:privacy.paragraph_two.two')}
          </Typography>
          <Typography>{t('pages:privacy.paragraph_two.three')}</Typography>
          <Typography>{t('pages:privacy.paragraph_two.four')}</Typography>
          <Typography>{t('pages:privacy.paragraph_two.five')}</Typography>
          <Typography variant="subtitle1">
            <strong>{t('pages:privacy.paragraph_two.contentB')}</strong>
            {t('pages:privacy.paragraph_two.content')}
          </Typography>
          <Typography>
            {t('pages:privacy.paragraph_two.items.0')}
            <strong>{t('pages:privacy.paragraph_two.items.1')}</strong>
            {t('pages:privacy.paragraph_two.items.2')}
            {t('pages:privacy.paragraph_two.items.3')}
            <strong>{t('pages:privacy.paragraph_two.items.4')}</strong>
            {t('pages:privacy.paragraph_two.items.5')}
            <strong>{t('pages:privacy.paragraph_two.items.6')}</strong>
            {t('pages:privacy.paragraph_two.items.7')}
          </Typography>
          <Typography>{t('pages:privacy.paragraph_two.six')}</Typography>

          <Typography variant="subtitle1">
            {t('pages:privacy.paragraph_three.title')}
          </Typography>

          <Typography>
            {t('pages:privacy.paragraph_three.one.0')}
            <Link target="_blank" to={{ pathname: '/policies/cookie' }}>
              {t('pages:privacy.paragraph_three.one.1')}
            </Link>
          </Typography>
          <Typography variant="subtitle1">
            {t('pages:privacy.paragraph_four.title')}
          </Typography>
          <Typography>{t('pages:privacy.paragraph_four.one')}</Typography>

          <Typography variant="subtitle1">
            {t('pages:privacy.paragraph_five.title')}
          </Typography>
          <Typography>{t('pages:privacy.paragraph_five.one')}</Typography>

          <Typography variant="subtitle1">
            {t('pages:privacy.paragraph_six.title')}
          </Typography>
          <Typography>
            {t('pages:privacy.paragraph_six.one.0')}

            <Link target="_blank" to={{ pathname: '/policies/cookie' }}>
              {t('pages:privacy.paragraph_six.one.1')}
            </Link>
          </Typography>

          <Typography variant="subtitle1">
            {t('pages:privacy.paragraph_seven.title')}
          </Typography>
          <Typography>{t('pages:privacy.paragraph_seven.one')}</Typography>
          <Typography>{t('pages:privacy.paragraph_seven.two')}</Typography>

          <Typography variant="subtitle1">
            {t('pages:privacy.paragraph_eight.title')}
          </Typography>
          <Typography>{t('pages:privacy.paragraph_eight.one')}</Typography>

          {rightsOfDataSubjects &&
            rightsOfDataSubjects.map((el, idx) => (
              <Grid container className={classes.containerBox} key={idx}>
                <Grid item xs={5} md={4}>
                  <Box
                    className={`${
                      idx % 2 === 0 ? classes.orange : classes.grey
                    } ${classes.table}`}
                  >
                    {el.first}
                  </Box>
                </Grid>
                <Grid item xs={7} md={8}>
                  <Box
                    className={idx % 2 === 0 ? classes.orange : classes.grey}
                  >
                    {el.second}
                  </Box>
                </Grid>
              </Grid>
            ))}
          <Typography>
            {t('pages:privacy.paragraph_eight.subone.0')}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:privacy@assonave.it"
            >
              {t('pages:privacy.paragraph_eight.subone.1')}
            </a>
          </Typography>
          <Typography>
            {t('pages:privacy.paragraph_nine.one.0')}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:privacy@assonave.it"
            >
              {t('pages:privacy.paragraph_nine.one.1')}
            </a>
          </Typography>
          <Typography>{t('pages:privacy.paragraph_nine.two')}</Typography>
        </Box>
      </Grid>
    </BlockContainer>
  );
};

export default PrivacyPolicy;
