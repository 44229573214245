import React from 'react';
import { Grid, Hidden, Box, Typography } from '@mui/material';
import logo from '../../assets/img/logo.png';
import { NavLink } from 'react-router-dom';
import Menu from './Menu/Menu';
import LanguageSelector from '../Header/LanguageSelector/LanguageSelector';
import LockIcon from '@mui/icons-material/Https';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { RESERVED_AREA_LINK } from '../../constants';

const useStyles = makeStyles((theme) => {
  return {
    header: {
      position: 'sticky',
      top: '0',
      width: '100%',
      zIndex: '100',
      display: 'block',
    },
    'MuiListItem-root': {},
    topbar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 10,
    },
    logoContainer: {
      padding: '20px',
    },
    linedinLink: {
      color: '#2867b2',
    },
    logo: {
      marginTop: 5,
      width: '50px',
    },
    button: {
      minWidth: '190px',
      textAlign: 'center',
      backgroundColor: 'white',
      textDecoration: 'none',
      padding: '6px',
      borderRadius: '5px',
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontWeight: 'bolder',
      border: '1px solid' + theme.palette.primary.main,
      '&:hover': {
        border: '1px solid white',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
    buttonIcon: {
      verticalAlign: 'bottom',
      marginRight: '10px',
    },
  };
});

const HeaderDesktop = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <>
      <Hidden mdDown>
        <Grid container alignItems="center" className={classes.topbar}>
          <Grid item xs={9}>
            <LogoContainer />
          </Grid>
          <Grid item xs={3} style={{ display: 'flex' }}>
            <a className={classes.button} href={RESERVED_AREA_LINK}>
              <LockIcon className={classes.buttonIcon}></LockIcon>
              {t('actions.reservedAreaButton')}
            </a>
            <span style={{ marginLeft: '20px' }}>
              <LanguageSelector></LanguageSelector>
            </span>
          </Grid>
        </Grid>
        <Box className={classes.header}>
          <Menu></Menu>
        </Box>
      </Hidden>
    </>
  );
};

export const LogoContainer = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  return (
    <Grid container alignItems="center">
      <Grid item>
        <NavLink to="/">
          <img className={classes.logo} src={logo} alt="logo"></img>
        </NavLink>
      </Grid>
      <Grid item>
        <Typography sx={{ color: '#fff' }} variant="h4">
          {t('app.name')}
        </Typography>
        <Typography sx={{ color: '#fff' }} variant="subtitle1">
          {t('app.subName')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HeaderDesktop;
