import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.primary.main,
    rowGap: 20,
  },
  subcontainer: {
    rowGap: 40,
    '& img': {
      width: '80%',
    },
    '& h5': {
      fontSize: '1rem',
      fontWeight: '700',
    },
    '& a': {
      color: theme.palette.primary.main,
      fontSize: '0.75rem',
      textDecoration: 'none',
    },
  },
  location: {
    marginTop: '0.25rem !important',
    marginBottom: '0.25rem !important',
    lineHeight: '1rem !important',
    '& a': {
      color: theme.palette.primary.main,
      fontSize: '0.75rem',
      textDecoration: 'none',
    },
  },
}));

function Contacts({
  logo,
  email,
  tel,
  address,
  website,
  otherLocations = [],
  subsidiaries = [],
}) {
  const classes = useStyles();
  return (
    <Grid className={classes.container} container direction="column">
      <Grid container direction="column" className={classes.subcontainer}>
        <img src={logo} alt="company-logo"></img>
        <Grid container direction="column">
          <Typography variant="h5">CONTATTI</Typography>
          {email.map((mail, idx) => (
            <Typography key={idx} variant="caption">
              {mail}
            </Typography>
          ))}
          <a href={website} target="_blank" rel="noreferrer">
            {website}
          </a>
          <Typography variant="caption">{tel}</Typography>
          <Typography variant="caption">{address}</Typography>
        </Grid>
        {!!otherLocations.length && (
          <Grid container direction="column">
            <Typography variant="h5">ALTRE SEDI</Typography>
            {otherLocations.map((location, idx) => (
              <Typography
                key={idx}
                className={classes.location}
                variant="caption"
                dangerouslySetInnerHTML={{ __html: location }}
              ></Typography>
            ))}
          </Grid>
        )}
        {!!subsidiaries.length && (
          <Grid container direction="column">
            <Typography variant="h5">SOCIETÀ CONTROLLATE</Typography>
            {subsidiaries.map((location, idx) => (
              <Typography
                key={idx}
                className={classes.location}
                variant="caption"
                dangerouslySetInnerHTML={{ __html: location }}
              ></Typography>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Contacts;
