import React from 'react';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import SliderContainer from '../SliderContainer';
function Header() {
  return (
    <>
      <HeaderDesktop />
      <HeaderMobile />
      <SliderContainer />
    </>
  );
}

export default Header;
