import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import logo from '../../../../assets/img/circle-logo.png';
import Palette from '../../../../components/Palette';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    color: theme.palette.primary.main,
    '& p': {
      fontWeight: 300,
    },
  },
  box: {
    flex: '1 1',
    width: '95%',
  },
  logo: {
    width: '75px',
    position: 'absolute',
    top: '-50px',
    right: '50%',
    transform: 'translateX(50%)',
  },
}));

function ContentContainer() {
  const classes = useStyles();
  const { t } = useTranslation('pages');
  return (
    <Grid className={classes.container} container direction="column">
      <img className={classes.logo} src={logo} alt=""></img>
      <Palette size={10} />
      <Typography
        style={{ margin: '20px 0' }}
        align="center"
        variant="h3"
        component="h3"
      >
        {t('home.title')}
      </Typography>
      {t('home.contents', { returnObjects: true }).map((content, idx) => (
        <Typography style={{ margin: '10px 0' }} key={idx} variant="body1">
          {content}
        </Typography>
      ))}
    </Grid>
  );
}

export default ContentContainer;
