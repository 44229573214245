import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomList from '../../../components/CustomList';
import { BlockContainer } from '../../GridContainer';
import { listStyles } from './data/listStyles';
const ShareholdersRequirements = ({ shareholderType = 'ordinary' }) => {
  const { t } = useTranslation('pages');
  const { title, contents } = t(`shareholdersRequirements.${shareholderType}`, {
    returnObjects: true,
  });
  return (
    <BlockContainer>
      <Typography variant="h3">{title}</Typography>
      <CustomList
        listStyles={listStyles[shareholderType]}
        contents={contents}
      />
    </BlockContainer>
  );
};

export default ShareholdersRequirements;
