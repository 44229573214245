import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockContainer } from '../../GridContainer';
import Benchmarks from './components/Benchmarks';
import BoxContainer from './components/BoxContainer';

const useStyles = makeStyles((theme) => ({
  feesContainer: {
    margin: `${theme.margin.titleMargin}px 0`,
  },
}));

const SubscriptionFees = () => {
  const [popupOpened, openPopup] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation('pages');
  const contents = t('subscriptionFees.contents', { returnObjects: true });
  return (
    <BlockContainer>
      <Benchmarks opened={popupOpened} triggerOpen={openPopup} />
      <Typography variant="h3">{t('subscriptionFees.title')}</Typography>
      <Grid
        className={classes.feesContainer}
        container
        justifyContent="space-between"
      >
        {contents.map((content, idx) => (
          <BoxContainer
            triggerOpen={openPopup}
            idx={idx}
            key={idx}
            {...content}
          />
        ))}
      </Grid>
    </BlockContainer>
  );
};

export default SubscriptionFees;
