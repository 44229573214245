import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@mui/material';

const CustomList = ({ listStyles = [], idx = 0, text, contents = [] }) => {
  return (
    <List sx={{ listStyle: listStyles[idx] }}>
      {contents?.map((content, cidx) => (
        <ListItem
          sx={{ display: 'list-item', paddingLeft: !listStyles[idx] ? 0 : 2 }}
          key={cidx}
        >
          {typeof content === 'string' ? content : content.text}
          {content.contents && (
            <CustomList listStyles={listStyles} {...content} idx={++idx} />
          )}
        </ListItem>
      ))}
    </List>
  );
};

CustomList.propTypes = {
  listStyles: PropTypes.array,
  text: PropTypes.string,
  contents: PropTypes.array,
  idx: PropTypes.number,
};

export default CustomList;
