import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from './components/Content';
import Contacts from './components/Contacts';
import { useParams } from 'react-router';
import { getShareHolder } from '../../../data/Shareholders/shareholdersData.ts';
import Name from './components/Name';
import HomeBox from '../Home/containers/HomeBox';
import Slider from '../../../components/Slider';
import NotFound from './NotFound';
const useStyles = makeStyles((theme) => ({
  container: {
    margin: '100px 0',
  },
  grid: {
    margin: '50px 0',
  },
  subcontainer: {
    margin: '100px 0',
  },
}));
const Shareholder = ({ type }) => {
  const classes = useStyles();
  const { t } = useTranslation('members');
  const { id } = useParams();
  const shareholder = getShareHolder(id, type, t);
  return (
    <>
      {shareholder?.page ? (
        <>
          {/* <Slider objectFit="cover" noshadow imageData={[shareholder.header]} /> */}
          <Slider
            objectFit="contain"
            noshadow
            imageData={[shareholder.header]}
          />
          <Container className={classes.container}>
            <Grid
              className={classes.subcontainer}
              container
              direction="row"
              spacing={2}
            >
              <Name name={shareholder.name} />
              <Grid md={4} xs={12} item className={classes.box}>
                <Contacts {...shareholder} />
              </Grid>
              <Grid md={8} xs={12} item className={classes.box}>
                <Content {...shareholder} />
              </Grid>
            </Grid>
            <HomeBox />
          </Container>
        </>
      ) : (
        <NotFound to="/shareholders" />
      )}
    </>
  );
};
export default Shareholder;
