export const links = [
  ['https://www.confindustria.it/'],
  '',
  '',
  ['https://www.pressmare.it/'],
  ['https://www.seaeurope.eu/'],
  [
    'http://clusterbig.it/',
    'https://www.waterborne.eu/',
    'https://transport.ec.europa.eu/transport-themes/clean-transport-urban-transport/alternative-fuels-sustainable-mobility-europe/renewable-and-low-carbon-fuels-value-chain-industrial-alliance_en',
    'https://transport.ec.europa.eu/transport-themes/sustainable-transport/european-sustainable-shipping-forum_en',
  ],
  [
    'https://www.oecd.org/sti/ind/shipbuilding-working-party.htm',
    'https://enmc.eu/',
  ],
];
