export const menuData = ({
  i18n,
  t,
  SubMenuItem,
  openHome,
  setOpenHome,
  openServices,
  setOpenServices,
  openTarget,
  setOpenTarget,
  openIter,
  setOpenIter,
  openInnovation,
  setOpenInnovation,
}) => {
  return [
    {
      exact: false,
      to: '/home',
      openCloseValue: openHome,
      openCloseCallback: setOpenHome,
      labelLink: t('menu.home'),
    },
    {
      exact: false,
      to: '/about',
      openCloseValue: openTarget,
      openCloseCallback: setOpenTarget,
      labelLink: t('menu.whoWeAre'),
      subMenu: {
        title: t('menu.whoWeAre'),
        subMenuList: [
          {
            keyName: 'mission',
            title: t('whoWeAre.mission'),
            linkTo: '/about/vision-and-mission',
          },
          {
            keyName: 'organs',
            title: t('whoWeAre.organs'),
            linkTo: '/about/organisational-structure',
          },
        ],
      },
    },
    {
      exact: false,
      to: '/join-us',
      labelLink: t('menu.subscription'),
      subMenu: {
        title: t('menu.subscription'),
        subMenuList: [
          {
            keyName: 'whySubscribe',
            title: t('subscription.whySubscribe'),
            linkTo: '/join-us/why-assonave',
          },
          {
            keyName: 'modules',
            title: t('subscription.modules'),
            linkTo: '/join-us/instructions-and-applications-forms',
          },
          {
            keyName: 'contributes',
            title: t('subscription.contributes'),
            linkTo: '/join-us/subscription-fees',
          },
        ],
      },
    },
    {
      exact: false,
      to: '/shareholders',
      labelLink: t('menu.shareholders'),
      subMenu: {
        title: t('menu.shareholders'),
        subMenuList: [
          {
            keyName: 'ordinaryList',
            title: t('shareholders.ordinaryList'),
            linkTo: '/shareholders/ordinary',
          },
          {
            keyName: 'ordinaryRequirements',
            title: t('shareholders.ordinaryRequirements'),
            linkTo: '/shareholders/ordinary/requirements',
          },
          {
            keyName: 'aggregateList',
            title: t('shareholders.aggregateList'),
            linkTo: '/shareholders/aggregate',
          },
          {
            keyName: 'aggregateRequirements',
            title: t('shareholders.aggregateRequirements'),
            linkTo: '/shareholders/aggregate/requirements',
          },
          {
            keyName: 'grinavRequirements',
            title: t('shareholders.grinavList'),
            linkTo: '/shareholders/grinav',
          },
          {
            keyName: 'grinavRequirements',
            title: t('shareholders.grinavRequirements'),
            linkTo: '/shareholders/grinav/requirements',
          },
        ],
      },
    },
    // {
    //   exact: false,
    //   to: '/documents',
    //   labelLink: t('menu.documents'),
    //   subMenu: {
    //     title: t('menu.documents'),
    //     subMenuList: [
    //       {
    //         keyName: 'documents',
    //         title: t('documents.documents'),
    //         linkTo: '/documents/documents',
    //       },
    //       {
    //         keyName: 'news',
    //         title: t('documents.news'),
    //         linkTo: '/documents/news',
    //       },
    //       {
    //         keyName: 'membership',
    //         title: t('documents.membership'),
    //         linkTo: '/documents/membership',
    //       },
    //     ],
    //   },
    // },
    // {
    //   exact: false,
    //   to: '/login',
    //   openCloseValue: openHome,
    //   openCloseCallback: setOpenHome,
    //   labelLink: t('menu.login'),
    // },
    {
      exact: false,
      to: '/contacts',
      openCloseValue: openTarget,
      openCloseCallback: setOpenTarget,
      labelLink: t('menu.contacts'),
    },
  ];
};
