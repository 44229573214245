import { List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlockContainer } from '../../GridContainer';

const Subscribe = () => {
  const { t } = useTranslation('pages');
  return (
    <BlockContainer>
      <Typography variant="h3">{t('subscription.title')}</Typography>
      <List sx={{ listStyle: 'disc' }}>
        {t('subscription.contents', { returnObjects: true }).map(
          (content, idx) => (
            <div key={idx}>
              <ListItem sx={{ display: 'list-item' }} key={idx}>
                {content.title}
              </ListItem>
              {content.contents && (
                <List sx={{ listStyle: 'inside', listStyleType: 'circle' }}>
                  {content.contents?.map((subContent, idx) => (
                    <ListItem sx={{ display: 'list-item' }} key={idx}>
                      {subContent}
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          ),
        )}
      </List>
    </BlockContainer>
  );
};

export default Subscribe;
