import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: `calc(100vh - ${148}px)`,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: 20,
  },
  grid: {},
}));
const NotFound = ({ to }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container>
      <Grid container className={classes.container} flexDirection="column">
        <Typography variant="h4">{t('pages:memberNotFound.title')}</Typography>
        <Typography variant="body1">
          {t('pages:memberNotFound.subtitle')}
        </Typography>
        <NavLink to={to || '/'}>{t('pages:memberNotFound.back')}</NavLink>
      </Grid>
    </Container>
  );
};

export default NotFound;
