import { createTheme, responsiveFontSizes } from '@mui/material/styles';
const color = {
  primary: {
    main: '#004e91',
    mainOpaque: '#407aac',
    dark: '#598bb7',
    light: '#739dc2',
    morelight: '#8cafcd',
    plusLight: '#a6c1d8',
  },
  secondary: {
    main: '#ffffff',
    mainOpaque: '#004e9194',
    light: '#BBBDBE',
    dark: '#404244',
    morelight: '#dbd1cf',
    moredark: '#978883',
  },
};

let theme = createTheme({
  zIndex: {
    backdropIndex: 1,
    subMenuIndex: 2,
  },
  margin: {
    titleMargin: 25,
  },
  palette: {
    background: {
      default: '#ffffff',
    },
    primary: {
      main: color.primary.main,
      mainOpaque: color.primary.mainOpaque,
      dark: color.primary.dark,
      light: color.primary.light,
      morelight: color.primary.morelight,
      plusLight: color.primary.plusLight,
    },
    secondary: {
      main: color.secondary.main,
      light: color.secondary.light,
      dark: color.secondary.dark,
      morelight: color.secondary.morelight,
      moredark: color.secondary.moredark,
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    subtitle1: {
      lineHeight: '1.25',
    },
    h2: {
      lineHeight: '1',
      fontWeight: 700,
      color: color.primary.main,
      marginTop: '2rem',
      fontSize: '2.3rem',
    },
    h3: {
      lineHeight: '1',
      fontWeight: 700,
      color: color.primary.main,
      marginBottom: '2rem',
      fontSize: '1.75rem',
    },
    h4: {
      lineHeight: '1',
      fontSize: '1.75rem',
    },
    h5: {
      lineHeight: '1',
      marginTop: 20,
      color: color.primary.main,
    },
    h6: {
      fontWeight: 700,
      color: color.primary.main,
    },
    body2: {
      lineHeight: '1.1',
      fontSize: '0.75rem',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: color.secondary.main,
        },
      },
    },
    MuiButton: {
      root: {
        color: color.primary.main,
        backgroundColor: 'white',
        borderRadius: 0,
        padding: 5,
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0',
        width: '100%',
        flexDirection: 'column',
        borderTop: '1px dotted white',
      },
    },
    MuiAccordion: {
      root: {
        background: 'red',
        padding: '0',
        width: '100%',
        borderTop: '1px dotted white',
      },
    },
  },
});
theme = responsiveFontSizes(theme);

export default theme;
