import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '../../consts/palette';
import logo from '../../assets/img/circle-logo.png';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  palette: {
    position: 'relative',
    width: '95%',
    flex: '1 1',
    margin: '0 auto',
  },
  logo: {
    position: 'absolute',
    top: '-30px',
    left: '10%',
    width: '50px',
  },
  childrenContainer: {},
}));

function PaletteContainer({ children, size = 10 }) {
  return children ? (
    <PaletteWithChildren size={size}>{children}</PaletteWithChildren>
  ) : (
    <Palette size={size} />
  );
}

function Palette({ size }) {
  return (
    <Grid container direction="row">
      <PaletteComponent size={size} />
    </Grid>
  );
}

function PaletteWithChildren({ size, children }) {
  return (
    <Grid wrap="nowrap" container direction="row">
      <Grid container direction="row">
        <PaletteComponent size={size} />
      </Grid>
      <Grid align="center" justifyContent="flex-end" container>
        {children}
      </Grid>
    </Grid>
  );
}

export function PaletteWithLogo({ size }) {
  const classes = useStyles();
  return (
    <Grid sx={{ position: 'relative' }} wrap="nowrap" container direction="row">
      <PaletteComponent size={size} />
      <img alt="" src={logo} className={classes.logo}></img>
    </Grid>
  );
}

function PaletteComponent({ size = 20 }) {
  const classes = useStyles();
  return palette.map((color, idx) => (
    <div
      key={idx}
      className={classes.palette}
      style={{ height: size || '100%', background: color }}
    ></div>
  ));
}
PaletteContainer.propTypes = {
  size: PropTypes.number,
};

PaletteWithLogo.propTypes = {
  size: PropTypes.number,
};

export default PaletteContainer;
