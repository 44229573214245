import React from 'react';
import { makeStyles } from '@mui/styles';

import { getLogos } from './utils';

const useStyles = makeStyles(() => ({
  logo__container: {
    marginTop: '4rem',
    marginBottom: '2rem',
    display: 'flex',
    placeItems: 'center',
    placeContent: 'center',
    '& > a': {
      padding: '1rem',
    },
    '@media screen and (max-width: 1280px)': {
      flexWrap: 'wrap',
    },
  },
  logo__img: {
    height: '60px',
  },
}));

const Logos = () => {
  const logos = getLogos();
  const styles = useStyles();

  return (
    <div className={styles.logo__container}>
      {logos.map((logo) => (
        <a href={logo.href} key={logo.key}>
          <img src={logo.src} alt={logo.alt} className={styles.logo__img} />
        </a>
      ))}
    </div>
  );
};

export default Logos;
