import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrg } from '../../data/orgStructureData';
import { BlockContainer } from '../GridContainer';
import NotFound from '../pages/ShareholderData/NotFound';
const useStyles = makeStyles((theme) => {
  return {
    img: {
      width: '70%',
    },
    '@media screen and (max-width: 900px)': {
      img: {
        width: '100% !important',
      },
    },
  };
});
const OrganizationalStructureMember = ({ title, status, desc }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const org = getOrg(t, id);

  useEffect(() => {
    if (!org) navigate('/not-found');
  }, [org, navigate]);
  return (
    <BlockContainer>
      {org ? <OrgComponent {...org} /> : <NotFound />}
    </BlockContainer>
  );
};

const OrgComponent = (org) => {
  const classes = useStyles();
  return (
    <BlockContainer>
      <Typography sx={{ textAlign: 'center' }} variant="h3">
        {org?.title}
      </Typography>
      <Grid container>
        <Grid item md={6}>
          <img
            className={classes.img}
            style={{ width: '75%' }}
            alt=""
            src={org?.src}
          ></img>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">{'Curriculum Vitae'}</Typography>
          {org?.desc.map((row, idx) => (
            <Typography
              sx={{ marginTop: '1em' }}
              variant="body1"
              key={idx}
              gutterBottom
            >
              {row}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </BlockContainer>
  );
};
export default OrganizationalStructureMember;
