import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import MainContent from './containers/MainContent';
import HomeBox from './containers/HomeBox';
import { BlockContainer } from '../../GridContainer';
const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('common:app.name')}</title>
      </Helmet>
      <BlockContainer>
        <MainContent />
        <HomeBox />
      </BlockContainer>
    </>
  );
};

export default Home;
