import A from '../../../components/A';
import { List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import GridContainer from '../../GridContainer';
import documentsData from './data/documentsLinks';
const HowToSubscribe = () => {
  const {
    // codice_etico_assonave,
    regolamento_assonave,
    statuto_assonave,
    modulo_adesione_aggregati,
    modulo_adesione_associati,
    modulo_adesione_grinav,
    codice_etico_e_dei_valori_associativi_giugno_2014,
  } = documentsData();
  const { t } = useTranslation('pages');
  return (
    <GridContainer>
      <Typography variant="h3">{t('howToSubscribe.title')}</Typography>
      <List sx={{ listStyle: 'number' }}>
        {t('howToSubscribe.contents', { returnObjects: true }).map(
          (content, idx) => (
            <ListItem sx={{ display: 'list-item' }} key={idx}>
              <Trans key={idx}>
                {content}
                <A show={idx === 0} href={modulo_adesione_associati} />
                <A show={idx === 0} href={modulo_adesione_aggregati} />
                <A show={idx === 0} href={modulo_adesione_grinav} />
                <A show={idx === 1} href={statuto_assonave} />
                <A show={idx === 1} href={regolamento_assonave} />
                <A
                  show={idx === 1}
                  href={codice_etico_e_dei_valori_associativi_giugno_2014}
                />
                {/* <A show={idx === 1} href={codice_etico_assonave} /> */}
                <A
                  show={idx === 1}
                  href="https://www.confindustria.it/home/chi-siamo/governance/codice-etico"
                />
              </Trans>
            </ListItem>
          ),
        )}
      </List>
      <Typography variant="body1">{t('howToSubscribe.c1')}</Typography>
      <Typography variant="body1">
        <Trans key="howToSubscribe.c2">
          {t('howToSubscribe.c2')}
          <A show href={statuto_assonave} />
          {/* <A show href={codice_etico_assonave} /> */}
          <A show href={regolamento_assonave} />
        </Trans>
      </Typography>
    </GridContainer>
  );
};

export default HowToSubscribe;
