/* eslint-disable no-unused-vars */
import { useState } from 'react';
import Slider from '../../components/Slider';
import { useTranslation } from 'react-i18next';
import { imageData } from './data/imageData';
import { useLocation } from 'react-router-dom';
import { HiddenRoutes } from './routesHook';
const SliderContainer = () => {
  const { i18n } = useTranslation();
  const [locale] = useState(i18n.language);
  const location = useLocation();
  const visible = HiddenRoutes(location);
  const key = imageHook(location);
  return (
    <>
      {visible && (
        <Slider
          timer={key === 'default' ? 5 : 0}
          imageData={imageData({ t: locale }, key)}
        />
      )}
    </>
  );
};

const imageHook = (location) => {
  const images = {
    director: '/about/organisational-structure/0',
    shareholders: '/shareholders',
    joinus: '/join-us',
  };
  if (location.pathname.includes(images.director)) {
    return 'director';
  }
  if (location.pathname.includes(images.shareholders)) {
    return 'shareholders';
  }
  if (location.pathname.includes(images.joinus)) {
    return 'joinus';
  }
  return 'default';
};

export default SliderContainer;
