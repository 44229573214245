import React from 'react';
import logo from '../../assets/img/logo.png';
import { NavLink } from 'react-router-dom';
import { makeStyles, withStyles } from '@mui/styles';
import MenuUpdate from './MenuMobileUpdate/MenuUpdate';
import { Grid, Hidden, Box, Typography } from '@mui/material';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import Backdrop from '@mui/material/Backdrop';
import { Fade as MenuIcon } from 'hamburger-react';
import MuiCollapse from '@mui/material/Collapse';
import { useTranslation } from 'react-i18next';

const Collapse = withStyles((theme) => ({
  container: {
    width: '100%',
  },
}))(MuiCollapse);

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '25%',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    position: 'sticky',
    top: '0',
    padding: '5px',
    height: '75px',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    zIndex: 100,
    color: '#fff',
  },
  closed: {
    borderBottom: '1px solid #2867b2',
  },
  submenu: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '15px',
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    zIndex: 100,
    '& ul': {
      color: 'white',
      textDecoration: 'none',
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    '& li': {
      color: 'white',
      textDecoration: 'none',
      margin: 0,
      padding: 5,
      width: '100%',
    },
    '& li a': {
      padding: '5px',
      width: '100%',
    },
    '& li:last-child a': {},
    '& li span:before': {
      padding: '10px',
      border: 'none',
      content: '"+"',
    },
  },
  'MuiListItem-root': {},
  bottomMenu: {
    padding: '10px',
  },
  headerIcon: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'space-around',
  },
}));

const HeaderMobile = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [openMenu, setOpenMenu] = React.useState(false);
  const openedStyle = !openMenu ? classes.closed : null;
  return (
    <>
      <Hidden mdUp>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.backdropIndex }}
          open={openMenu}
          onClick={() => setOpenMenu(!openMenu)}
        />
        <Box className={[classes.header, openedStyle].join(' ')}>
          <NavLink to="/" className={classes.headerIcon}>
            <img className={classes.logo} src={logo} alt="logo"></img>
            <Typography sx={{ color: '#fff' }} variant="h4">
              {t('app.name')}
            </Typography>
          </NavLink>
          <MenuIcon size={20} toggled={openMenu} toggle={setOpenMenu} />
        </Box>
        <Box className={classes.submenu}>
          <Collapse
            style={{ width: '100%' }}
            in={openMenu}
            timeout="auto"
            unmountOnExit
          >
            {/* <MenuMobile openClose={setOpenMenu} state={openMenu} /> */}
            <MenuUpdate openClose={setOpenMenu} state={openMenu} />
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              className={classes.bottomMenu}
            >
              <Grid item>
                <Grid container justify="space-between" spacing={1}>
                  <Grid item>
                    <LanguageSelector></LanguageSelector>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </Hidden>
    </>
  );
};

export default HeaderMobile;
