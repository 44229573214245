import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import SubMenuItemContainer from './SubMenuItemContainer';

const useStyles = makeStyles((theme) => ({
  selected: {
    paddingBottom: '0',
    borderBottom: `none`,
  },
  link: {
    position: 'relative',
    '& a': {
      fontSize: '.8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

function MenuItem({
  subMenu,
  background,
  exact,
  to,
  openClose = () => {},
  labelLink,
  target = '_self',
}) {
  const [hovering, setHovering] = useState(false);
  const classes = useStyles();
  return (
    <li
      onMouseEnter={() => {
        if (!hovering) {
          openClose(true);
          setHovering(true);
        }
      }}
      onClick={() => {
        openClose(false);
        setHovering(false);
      }}
      onMouseLeave={() => {
        openClose(false);
        setHovering(false);
      }}
      className={classes.link}
      style={{ background: background }}
    >
      <NavLink
        target={target}
        to={to}
        className={({ isActive }) => (isActive ? classes.selected : '')}
      >
        {labelLink}
      </NavLink>
      {subMenu && (
        <SubMenuItemContainer
          subMenu={subMenu}
          show={hovering}
        ></SubMenuItemContainer>
      )}
    </li>
  );
}

MenuItem.propTypes = {
  labelLink: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  openClose: PropTypes.func,
  exact: PropTypes.bool,
};

export default MenuItem;
