import codice_etico_assonave from '../../../../assets/documents/codice_etico_assonave.pdf';
import codice_etico_e_dei_valori_associativi_giugno_2014 from '../../../../assets/documents/codice_etico_e_dei_valori_associativi_giugno_2014.pdf';
import regolamento_assonave from '../../../../assets/documents/regolamento_assonave.pdf';
import statuto_assonave from '../../../../assets/documents/statuto_assonave.pdf';
import modulo_adesione_aggregati from '../../../../assets/documents/modulo_adesione_aggregati.doc';
import modulo_adesione_associati from '../../../../assets/documents/modulo_adesione_associati.doc';
import modulo_adesione_grinav from '../../../../assets/documents/modulo_adesione_grinav.doc';
const documentsData = () => {
  const documents = {
    codice_etico_assonave,
    regolamento_assonave,
    statuto_assonave,
    modulo_adesione_aggregati,
    modulo_adesione_associati,
    modulo_adesione_grinav,
    codice_etico_e_dei_valori_associativi_giugno_2014,
  };
  return documents;
};
export default documentsData;
