import slider4 from '../../../assets/img/sliderHome/1.jpg';
import slider2 from '../../../assets/img/sliderHome/2.jpg';
import slider9 from '../../../assets/img/sliderHome/3.jpg';
import slider10 from '../../../assets/img/sliderHome/4.jpg';
import slider11 from '../../../assets/img/sliderHome/5.jpg';
import slider6 from '../../../assets/img/sliderHome/6.jpg';
import slider7 from '../../../assets/img/sliderHome/7.jpg';

export const imageData = ({ t }, key = 'default') => {
  const images = {
    default: [
      {
        image: t === 'it' ? slider4 : slider4,
        text: 'textprova',
      },
      {
        image: slider9,
        text: '',
      },
      {
        image: slider10,
        text: '',
      },
      {
        image: slider2,
        text: '',
      },
    ],
    director: [
      {
        image: slider11,
        text: '',
      },
    ],
    shareholders: [
      {
        image: slider6,
        text: '',
      },
    ],
    joinus: [
      {
        image: slider7,
        text: '',
      },
    ],
  };
  return images[key];
};
