import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { NavLink } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    boxSizing: 'border-box',
    padding: 20,
    color: '#fff',
    borderRadius: 5,
    width: '90% !important',
    '@media screen and (max-width: 600px)': {
      width: '100% !important',
    },
    marginTop: 25,
    '& h4': {
      width: '90%',
      margin: '0 auto 10px auto',
      textAlign: 'center',
      userSelect: 'none',
      borderBottom: '1px solid rgba(200,200,200,.3)',
      paddingBottom: 10,
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      margin: '5px 0',
    },
  },
}));
const ShareholderBox = ({ title, requirementsUrl, listUrl }) => {
  const classes = useStyles();
  return (
    <Grid alignItems="center" item md={4} xs={12}>
      <Grid flexDirection="column" container className={classes.container}>
        <Typography variant="h4">{title}</Typography>
        <NavLink to={requirementsUrl.to}>{requirementsUrl.title}</NavLink>
        <NavLink to={listUrl.to}>{listUrl.title}</NavLink>
      </Grid>
    </Grid>
  );
};

export default ShareholderBox;
