import FdM from '../../assets/img/logos/FdM.jpg';
import ENMC from '../../assets/img/logos/ENMC.jpg';
import SeaEurope from '../../assets/img/logos/Sea_Europe.jpg';
import ClusterBig from '../../assets/img/logos/BIG.PNG';
import Waterbone from '../../assets/img/logos/Waterbone.png';
import RLCFAlliance from '../../assets/img/logos/RLCF_Alliance.png';

export const getLogos = () => {
  return [
    {
      src: FdM,
      href: 'https://federazionedelmare.it/',
      alt: 'Federazione del mare',
      key: 'FdM',
    },
    {
      src: SeaEurope,
      href: 'https://www.seaeurope.eu/',
      alt: 'Sea Europe',
      key: 'SeaEurope',
    },
    {
      src: ClusterBig,
      href: 'http://clusterbig.it/',
      alt: 'Blue Italian Growth',
      key: 'ClusterBig',
    },
    {
      src: Waterbone,
      href: 'https://waterborne.eu/',
      alt: 'Waterborne',
      key: 'Waterbone',
    },
    {
      src: RLCFAlliance,
      href: 'https://transport.ec.europa.eu/transport-themes/clean-transport-urban-transport/alternative-fuels-sustainable-mobility-europe/renewable-and-low-carbon-fuels-value-chain-industrial-alliance_en',
      alt: 'Renewable and low carbon fuels industrial alliance',
      key: 'RLCFAlliance',
    },
    {
      src: ENMC,
      href: 'https://www.enmc.eu/',
      alt: 'EU network of maritime clusters',
      key: 'ENMC',
    },
  ];
};
