import { List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
// import { NavLink } from 'react-router-dom';
import { BlockContainer } from '../GridContainer';
// import { orgStructureData } from '../../data/orgStructureData';
const OrganizationalStructure = () => {
  const { t } = useTranslation('pages');
  // const members = orgStructureData(t);
  const paragraphs = t('organisational_structure.paragraphs', {
    returnObjects: true,
  });
  return (
    <BlockContainer>
      <Typography variant="h3">
        {t(`organisational_structure.title`)}
      </Typography>
      <List>
        {/* {members?.map((member, idx) => (
          <NavLink key={idx} to={`${idx}`}>
            {member.status}
          </NavLink>
        ))} */}
        {paragraphs.map((paragraph, idx) => (
          <List key={idx}>
            <Typography variant="h3" sx={{ marginBottom: 1 }} key={idx}>
              {paragraph.title}
            </Typography>
            {paragraph.contents?.map((text, idx) => (
              <Trans key={idx} i18nKey="pages.organisational_structure">
                <Typography sx={{ marginTop: '5px' }} variant="body1">
                  {text}
                  <a></a>
                </Typography>
              </Trans>
            ))}
            {paragraph.lists?.map((list, idx) => (
              <React.Fragment key={idx}>
                {list.title && (
                  <Typography variant="h5">{list.title}</Typography>
                )}
                {list.content && (
                  <Typography sx={{ marginTop: '2px' }} variant="body1">
                    {list.content}
                  </Typography>
                )}
                {list.items && (
                  <List sx={{ listStyle: 'inside' }}>
                    {list.items.map((item, idx) => (
                      <ListItem sx={{ display: 'list-item' }} key={idx}>
                        {item}
                      </ListItem>
                    ))}
                  </List>
                )}
              </React.Fragment>
            ))}
          </List>
        ))}
      </List>
    </BlockContainer>
  );
};

export default OrganizationalStructure;
