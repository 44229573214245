import director_img from '../assets/img/orgStructure/direttrice.png';
import chairman_img from '../assets/img/orgStructure/presidente.jpg';
export const orgStructureData = (t) => {
  const orgStructure = [
    {
      id: 0,
      title: t('orgStructure:director.title'),
      desc: t('orgStructure:director.desc', { returnObjects: true }),
      status: t('orgStructure:director.status'),
      src: director_img,
    },
    {
      id: 1,
      title: t('orgStructure:chairman.title'),
      desc: t('orgStructure:chairman.desc', { returnObjects: true }),
      status: t('orgStructure:chairman.status'),
      src: chairman_img,
    },
  ];
  return orgStructure;
};

export const getOrg = (t, idx) => orgStructureData(t)[idx];
