import { useNavigate } from 'react-router';
import { RESERVED_AREA_LINK } from '../../../../constants';
const MenuHook = () => {
  const navigate = useNavigate();

  const accordionClickHandler = (
    accId,
    sectionOpened,
    prev,
    openSection,
    openMenu,
    subMenu,
    linkTo,
  ) => {
    if (accId === sectionOpened) {
      if (prev) {
        openSection(prev);
      } else {
        openSection('');
      }
    } else {
      openSection(accId);
    }
    if (!subMenu && linkTo !== RESERVED_AREA_LINK) {
      openMenu(false);
      navigate(linkTo);
    }
  };

  return {
    accordionClickHandler,
  };
};

export default MenuHook;
